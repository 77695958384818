import { configureStore } from '@reduxjs/toolkit';
import districtReducer from './slices/districtSlice';
import talukaReducer from './slices/talkuaslice';

const store = configureStore({
  reducer: {
    districts: districtReducer,
    talukas: talukaReducer
  }
});

export default store;
