import React, { useRef, useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Function to calculate centroid for a given GeoJSON geometry
const calculateCentroid = (geometry) => {
  const coords = geometry.type === 'MultiPolygon'
    ? geometry.coordinates.flat(2) // Flatten multi-polygon coordinates
    : geometry.coordinates[0]; // Single polygon coordinates

  const lngs = coords.map(coord => coord[0]);
  const lats = coords.map(coord => coord[1]);

  const centroidLng = (Math.min(...lngs) + Math.max(...lngs)) / 2;
  const centroidLat = (Math.min(...lats) + Math.max(...lats)) / 2;

  return [centroidLat, centroidLng];
};

// Component to center the GeoJSON and set max bounds
const CenterMap = ({ data }) => {
  const map = useMap();

  useEffect(() => {
    if (data && data.features.length > 0) {
      try {
        const geojsonLayer = L.geoJSON(data);
        const bounds = geojsonLayer.getBounds();

        // Calculate centroid for the GeoJSON features
        const centroid = calculateCentroid(data.features[0].geometry);

        if (bounds.isValid()) {
          map.fitBounds(bounds, { padding: [20, 20] });
          map.setMaxBounds(bounds);
        } else {
          console.warn("GeoJSON bounds are not valid.");
        }

        // Center map on the centroid
        map.setView(centroid, map.getZoom());
      } catch (error) {
        console.error("Error processing GeoJSON data:", error);
      }
    }
  }, [data, map]);

  return null;
};

// Main component to render the map and handle PDF export
const PrintControl = ({ geojsonData }) => {
  const mapRef = useRef(null);
  const [isExporting, setIsExporting] = useState(false);

  const[center,setCenter] = useState();
  const cordinate = calculateCentroid(geojsonData);

  setCenter(cordinate);

  const exportMapAsPDF = () => {
    if (mapRef.current) {
      setIsExporting(true);

      // Set devicePixelRatio to 1 for consistency between screen and canvas
      const originalPixelRatio = window.devicePixelRatio;
      window.devicePixelRatio = 1;

      // Capture the map using html2canvas
      html2canvas(mapRef.current, {
        useCORS: true,
        scale: 1, // Ensures the capture is true to the UI
        allowTaint: false, // Prevents issues with CORS-related errors
        backgroundColor: null, // If your UI has transparency
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Create a new jsPDF instance in landscape mode
        const pdf = new jsPDF('l', 'mm', 'a4');

        // PDF dimensions
        const pdfWidth = 297; // A4 landscape width in mm
        const pdfHeight = 210; // A4 landscape height in mm
        const margin = 15; // Margin for header and footer

        // Define header and footer height
        const headerHeight = 20;
        const footerHeight = 15;

        // Define the image size, considering header and footer
        const imgWidth = pdfWidth - 2 * margin;
        const imgHeight = pdfHeight - headerHeight - footerHeight - 2 * margin;

        // Add a centered title with a border
        pdf.setFontSize(16);
        pdf.setTextColor(0, 0, 0);
        pdf.text('Area Map', pdfWidth / 2, margin + 10, { align: 'center' });

        // Add a logo in the top-right corner (replace 'logo.png' with your image path or URL)
        const logo = 'logo192.png'; // Ensure the correct path for the logo image
        pdf.addImage(logo, 'PNG', pdfWidth - 40, margin, 30, 20); // Position the logo

        // Add a footer
        pdf.setFontSize(10);
        pdf.text('Generated on: ' + new Date().toLocaleString(), margin, pdfHeight - margin);

        // Draw a bottom border for the header
        pdf.setLineWidth(0.5);
        pdf.line(margin, headerHeight + margin, pdfWidth - margin, headerHeight + margin); // Bottom border of header

        // Add the map image to the PDF and center it between the header and footer
        const x = margin;
        const y = margin + headerHeight;

        // Add the map image to the PDF, ensuring the map stays centered
        pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

        // Save the PDF
        pdf.save('centered_map_with_header_footer.pdf');

        // Restore the original devicePixelRatio
        window.devicePixelRatio = originalPixelRatio;

        setIsExporting(false);
      });
    }
  };

  return (
    <div>
      <button onClick={exportMapAsPDF} disabled={isExporting}>
        {isExporting ? 'Exporting...' : 'Export Full Page Map as PDF'}
      </button>
      <div ref={mapRef} style={{ height: '500px', width: '100%' }}>
        <MapContainer
          zoom={10}
          style={{ height: '100%', width: '100%' }}
          center={center}
          dragging={false}         // Disable dragging
          touchZoom={false}        // Disable zoom by touch
          scrollWheelZoom={false}  // Disable zoom by scroll wheel
          doubleClickZoom={false}  // Disable zoom by double click
          zoomControl={false}      // Disable zoom control buttons
          keyboard={false}         // Disable keyboard interactions
          maxBoundsViscosity={1.0} // Ensure the map bounces back to the max bounds
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <CenterMap data={geojsonData} />
          <GeoJSON data={geojsonData} />
        </MapContainer>
      </div>
    </div>
  );
};

export default PrintControl;
