import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = "https://geoserver.fornaxenergytech.com";
const username = 'admin';
const password = 'geoserver';

export const fetchDistricts = createAsyncThunk('districts/fetchDistricts', async () => {
  const response = await axios.get(`${baseUrl}/geoserver/wfs`, {
    params: {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'Gis_Gujarat:District',
      outputFormat: 'application/json'
    },
    auth: {
      username,
      password
    }
  });
  return response.data.features.map(feature => ({
    id: feature.properties.id,
    name: feature.properties.district_n,
    geometry: feature.geometry
  }));
});

const districtSlice = createSlice({
  name: 'districts',
  initialState: {
    districts: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDistricts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.districts = action.payload;
      })
      .addCase(fetchDistricts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default districtSlice.reducer;
