import React, { useState, useRef, useEffect, useCallback } from "react";
import axios from "axios";
import L from "leaflet";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  Marker,
  Polyline,
  GeoJSON,
  Circle,
  useMap,
  Tooltip,
  useMapEvents,
  ZoomControl,
  WMSTileLayer
} from "react-leaflet";
import * as turf from "@turf/turf";
import { BingLayer } from "react-leaflet-bing-v2";
import "leaflet/dist/leaflet.css";
import "./css/map_view.css";
import PointData from "../assets/sec_point";
import Linedata from "../assets/sec_line";
import RightSidebar from "./RightSidebar";
import LeftSidebar from "./LeftSidebar";
import TopNavbar from "./TopNavbar";
import {
  calculateDistance,
  getMidpoint,
  createDistanceIcon,
  pointsOnLine,
  convertClassNameString,
} from "../utils/map";

// Import custom marker icon images
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIconShadow from "leaflet/dist/images/marker-shadow.png";
import RightSideBaseLayer from './BaseLayer/RightSideBaseLayer';
import RightSideDrawBar from "./BaseLayer/RightSideDrawBar";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { renderToStaticMarkup } from 'react-dom/server';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-easyprint/dist/bundle';

// import L from 'leaflet';
import 'leaflet-draw';
// import 'leaflet-geometryutil';
//import jsPDF from "jspdf";
import 'leaflet-geometryutil';

const MapEvents = ({
  setPointGeometry,
  measureMode,
  setMeasureMode,
  setMeasureResult,
  setDrawnLayers,
  setSelectedData,
  setMeasureDetail
  // setSelectedLayer,
}) => {
  const map = useMap();

  const drawnItems = useRef(new L.FeatureGroup());
  const [drawing, setDrawing] = useState(false);
  const [currentLayer, setCurrentLayer] = useState(null);
  const[selectedLayer,setSelectedLayer] = useState();
  const [selectedLayerInfo, setSelectedLayerInfo] = useState(null);
  const [counter, setCounter] = useState({ point: 0, length: 0, area: 0, circle: 0 });
  const [layerNames, setLayerNames] = useState(new Map());
  const [cornerMarkers, setCornerMarkers] = useState(new Map());
  const [defaultStyles, setDefaultStyles] = useState(new Map()); // Store default styles


  useEffect(() => {
    map.addLayer(drawnItems.current);
    return () => {
      map.removeLayer(drawnItems.current);
    };
  }, [map]);

  const getLayerName = (type) => {
    let name = '';
    switch (type) {
      case 'point':
        name = `Point ${String.fromCharCode(65 + counter.point)}`;
        setCounter((prev) => ({ ...prev, point: prev.point + 1 }));
        break;
      case 'length':
        name = `Length ${String.fromCharCode(65 + counter.length)}`;
        setCounter((prev) => ({ ...prev, length: prev.length + 1 }));
        break;
      case 'area':
        name = `Area ${String.fromCharCode(65 + counter.area)}`;
        setCounter((prev) => ({ ...prev, area: prev.area + 1 }));
        break;
      case 'circle':
        name = `Circle ${String.fromCharCode(65 + counter.circle)}`;
        setCounter((prev) => ({ ...prev, circle: prev.circle + 1 }));
        break;
      default:
        break;
    }
    return name;
  };

  const finalizeCurrentLayer = () => {
    if (currentLayer) {
      setDrawing(false);
      setCurrentLayer(null);

      // Remove corner markers if they exist
      if (cornerMarkers.has(currentLayer._leaflet_id)) {
        cornerMarkers.get(currentLayer._leaflet_id).forEach((marker) => map.removeLayer(marker));
        setCornerMarkers((prev) => {
          const updatedMarkers = new Map(prev);
          updatedMarkers.delete(currentLayer._leaflet_id);
          return updatedMarkers;
        });
      }
    }
  };

  const handleDoubleClick = useCallback(() => {
    if (drawing) {
      finalizeCurrentLayer();
    }
  }, [drawing, finalizeCurrentLayer]);

  const handleLayerClick = ({layer,name,geom}) => {
    // Check if the clicked layer is already selected
    if (selectedLayer && selectedLayer._leaflet_id === layer._leaflet_id) {
      // The layer is already selected, so we should remove it
      drawnItems.current.removeLayer(layer); // Remove the layer from the drawnItems feature group
  
      // Reset the selected layer state
      setSelectedLayer(null);
    } else {
      // Get the previous selected layer
      const prevSelectedLayer = selectedLayer;
  
      // Reset the style of the previously selected layer
      if (prevSelectedLayer) {
        const defaultStyle = defaultStyles.get(prevSelectedLayer._leaflet_id) || { color: '#3388ff' }; // Default color
        prevSelectedLayer.setStyle(defaultStyle);
      }
  
      // Set the style of the currently clicked layer
      const selectedLayerStyle = { color: 'red' };
      layer.bindTooltip("Selected",name);
  
      // Update the selected layer state
      setSelectedLayer(layer);
  
      // Get the name of the clicked layer
      // const name = layerNames.get(layer._leaflet_id);
  
      // Calculate measurement based on the layer type
      let measurement = null;
      let type = '';
      
      if (layer instanceof L.Polygon) {
        type = 'area';
        // Calculate area in square kilometers
        measurement = (L.GeometryUtil.geodesicArea(layer.getLatLngs()[0]) / 1e6).toFixed(3); // Convert to km² and round to 3 decimal places
      } else if (layer instanceof L.Polyline) {
        type = 'length';
        // Calculate length in kilometers
        measurement = (L.GeometryUtil.length(layer.getLatLngs()) / 1000).toFixed(3); // Convert to km and round to 3 decimal places
      } else if (layer instanceof L.Marker) {
        type = 'point';
        // Get latitude and longitude
        const latlng = layer.getLatLng();
        measurement = {
          latitude: latlng.lat.toFixed(3),
          longitude: latlng.lng.toFixed(3),
        };
      } else if (layer instanceof L.Circle) {
        type = 'circle';
        // Calculate area in square kilometers
        const radiusInKm = layer.getRadius() / 1000; // Convert to kilometers
        measurement = (Math.PI * radiusInKm * radiusInKm).toFixed(3); // Area in km² and round to 3 decimal places
      }
  
      // Store the detail
      const detail = { measurement, type, name,geom };
  
      console.error("Detail of geom",detail);
      // Optionally update measure result or other state as needed
      setMeasureDetail(detail);
      console.log("Detail:", detail);
    }
  };
  
  
  
  
  useEffect(() => {
    if (measureMode) {
      // Finalize any ongoing drawing when measureMode changes
      if (drawing) {
        finalizeCurrentLayer();
      }

      const drawControl = new L.Control.Draw({
        position: 'topleft',
        edit: {
          featureGroup: drawnItems.current,
          poly: { allowIntersection: false },
        },
        draw: {
          polyline: measureMode === 'length',
          polygon: measureMode === 'area',
          circle: measureMode === 'circle',
          marker: measureMode === 'point',
          rectangle: false,
          circlemarker: false,
        },
      });

      map.addControl(drawControl);

      map.on(L.Draw.Event.CREATED, (e) => {
        const layer = e.layer;
        const name = getLayerName(measureMode);

      
        layer.bindTooltip(name, { permanent: true, direction: 'top' }).addTo(map);
        drawnItems.current.addLayer(layer);
        setLayerNames((prev) => new Map(prev).set(layer._leaflet_id, name));

        let latlngs = [];
        let type = '';

        if (layer instanceof L.Marker) {
          latlngs = [layer.getLatLng()];
          type = 'point';
        } else if (layer instanceof L.Polyline) {
          latlngs = layer.getLatLngs();
          type = 'length';
          if (layer instanceof L.Polygon) {
            type = 'area';
            latlngs = latlngs[0];
          }
        } else if (layer instanceof L.Circle) {
          latlngs = [layer.getLatLng()];
          type = 'circle';
        }

        const cornerNames = 'abcdefghijklmnopqrstuvwxyz'.split('');
        const geometry = latlngs.map((latlng, index) => ({
          name: cornerNames[index],
          lat: latlng.lat.toFixed(6),
          long: latlng.lng.toFixed(6),
        }));
// Ensure the polygon is closed by adding the first point to the end
const closedLatlngs = [...latlngs, latlngs[0]]; // Adds the first point to the end
let geom; // Use `let` instead of `const` since `geom` is being reassigned

if (type === "length") {
  // LINESTRING: A series of connected line segments
  geom = `LINESTRING(${latlngs.map(latlng => `${latlng.lng.toFixed(6)} ${latlng.lat.toFixed(6)}`).join(', ')})`;
} else if (type === "point") {
  // POINT: A single point
  const firstPoint = latlngs[0]; // Assuming you want the first point for a single point geometry
  geom = `POINT(${firstPoint.lng.toFixed(6)} ${firstPoint.lat.toFixed(6)})`;
} else if (type === "area") {
  // POLYGON: A closed shape
  geom = `POLYGON((${closedLatlngs.map(latlng => `${latlng.lng.toFixed(6)} ${latlng.lat.toFixed(6)}`).join(', ')}))`;
} else if (type === "circle") {
  // Approximate CIRCLE: Convert circle to POLYGON with a specified number of points
  const radius = 0.01; // Adjust this value as needed
  const numPoints = 36; // Number of points to approximate the circle
  const center = latlngs[0]; // Assuming the first point is the center
  
  const circlePoints = Array.from({ length: numPoints }, (_, i) => {
    const angle = (i / numPoints) * 2 * Math.PI;
    const dx = radius * Math.cos(angle);
    const dy = radius * Math.sin(angle);
    return {
      lat: center.lat + dy,
      lng: center.lng + dx
    };
  });

  const closedCirclePoints = [...circlePoints, circlePoints[0]]; // Close the circle
  geom = `POLYGON((${closedCirclePoints.map(latlng => `${latlng.lng.toFixed(6)} ${latlng.lat.toFixed(6)}`).join(', ')}))`;
}
// Convert latlngs to WKT POLYGON format
console.log("Check geom",geom);

        layer.on('click', () => handleLayerClick({layer,name,geom}));
        setSelectedLayerInfo({ layer, name, latlngs, type });
        setSelectedData({ layername: name, geometry});

        console.error(geometry,name,geom);
        console.error(geom);

        setCurrentLayer(layer);
      });

      return () => {
        map.removeControl(drawControl);
        map.off(L.Draw.Event.CREATED);
      };
    }
  }, [map, measureMode, drawnItems, getLayerName, setLayerNames, setSelectedData, setSelectedLayerInfo, finalizeCurrentLayer, drawing, layerNames, selectedLayer, setMeasureResult]);

  return null;
};

export default MapEvents;