import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const baseUrl = "https://geoserver.fornaxenergytech.com";
const username = 'admin';
const password = 'geoserver';

export const fetchTalukas = createAsyncThunk('talukas/fetchTalukas', async (districtId) => {
  const response = await axios.get(`${baseUrl}/geoserver/wfs`, {
    params: {
      service: 'WFS',
      version: '1.0.0',
      request: 'GetFeature',
      typeName: 'Gis_Gujarat:taluka',
      outputFormat: 'application/json',
      CQL_FILTER: `district_i='${districtId}'`
    },
    auth: {
      username,
      password
    }
  });
  return response.data.features.map(feature => ({
    id: feature.properties.id,
    name: feature.properties.taluka_nam,
    districtId: feature.properties.district_i,
    geometry: feature.geometry
  }));
});

const talukaSlice = createSlice({
  name: 'talukas',
  initialState: {
    talukas: [],
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTalukas.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTalukas.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.talukas = action.payload;
      })
      .addCase(fetchTalukas.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default talukaSlice.reducer;
