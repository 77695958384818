import React, { useState, memo } from 'react';
import { useDropzone } from 'react-dropzone';
import '../components/StyleModal/modal.css'; // Import your CSS file

const UploadModal = ({ handleClose,setUploadedFiles ,uploadedFiles}) => {
  const [loading, setLoading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const[error,setError] = useState(false);

  const onDrop = (acceptedFiles) => {
    // Validate file types
    const validFiles = acceptedFiles.filter((file) =>
      ['image/png', 'image/jpeg', 'application/pdf'].includes(file.type)
    );

    if (validFiles.length !== acceptedFiles.length) {
      alert('Some files are invalid. Please upload only images (.png, .jpeg) and PDFs (.pdf).');
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]); // Add valid files to the state
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*, application/pdf', // Accept specific file types
  });

  const handleUpload = async () => {
    // Handle upload logic here (API call or something)
    setError(false); 
    if(uploadedFiles.length > 0){
    setLoading(true);
    setUploadSuccessful(false);
    
    // Simulate API call (replace this with your actual upload logic)
    setTimeout(() => {
      console.log('Uploaded files:', uploadedFiles);
      setLoading(false);
      setUploadSuccessful(true);
      // Reset uploaded files if needed
      // setUploadedFiles([]);
      // Optionally close modal
      // handleClose();
    }, 2000);
  
    }else{
        setError(true);
    }
};


  return (
    <>
      <div className="modal-overlay">
        <div className={`modal-content ${error ? 'vibrate' : ''}`}  style={{ marginTop: '200px', padding: '10px' }}>
          <div style={{ background: '#6B8A7A', color: '#fff' }}>
            <button className="modal-close-button" onClick={handleClose} style={{ transform: 'translate(-5px, 0px)' }}>
              &times;
            </button>
            <h5 className="modal-title">Upload </h5>
          </div>
          <p style={{ fontSize: '12px', textAlign: 'left', padding: '5px', fontWeight: 'italic', color:error ? 'red' : 'black'  }}>
            <strong>Instruction:</strong> Please upload only image files (.png, .jpeg) or documents (.pdf). {error && ( "Please Select Files." ) }
          </p>

          <div className="container mt-2" style={{ width: '100%' }}>
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'active' : ''}`}
              style={{
                border: '2px dashed #999',
                padding: '20px',
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the files here...</p>
              ) : (
                <p>Drag and drop some files here, or click to select files</p>
              )}
            </div>

            {uploadedFiles.length > 0 && (
              <div className="uploaded-files-list">

                <ul>
                  {uploadedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}

            <div className="p-3">
              {/* Upload Button */}
              <button className="modal-upload-button rounded border p-2" onClick={handleUpload} disabled={loading || uploadSuccessful}>
                {loading ? (
                  <span>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {' Uploading...'}
                  </span>
                ) : uploadSuccessful ? (
                  'Uploaded'
                ) : (
                  'Upload Files'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(UploadModal);
