import React, { useEffect, useState, memo } from 'react';
import '../StyleModal/StyleModal'; // Import your CSS file

// import React, { memo } from 'react';

const LineModal = ({ closeModal, isChecked, setIsChecked, color, setColor,setIsTransformers,isTransformerChecked, substationId }) => {
  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    console.log("Your Click",isChecked);
  };

  const handleColorChange = (event) => {
    // Update the color for the specific substation
    setColor((prevColors) => ({
      ...prevColors,
      [substationId]: event.target.value, // Set the new color for this substation
    }));
  };

  const handleTransformerChange = () =>{
    setIsTransformers(!isTransformerChecked);
  }

  console.log(color);
  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content" style={{ padding: '10px', margin: 0 }}>
          <div style={{ background: '#6B8A7A', color: '#fff' }}>
            <button className="modal-close-button" onClick={closeModal} style={{ transform: 'translateY(10px)' }}>
              &times;
            </button>
            <h5 className="modal-title"> Filter </h5>
          </div>

          <div className="container" style={{ width: '100%' }}>
            <div className="card" style={{ border: '1px solid black' }}>
              <div className="card-body">
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  <li
                    className="bg-light"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <p style={{ margin: 0 }}>
                      <input
                        type="checkbox"
                        name="viewLineNetwork"
                        value={isChecked}
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />{' '}
                      View Line
                    </p>
                  </li>

                  <li
                    className="bg-light"
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <p style={{ margin: 0 }}>
                      <input
                        type="checkbox"
                        name="viewLineNetwork"
                        value={isTransformerChecked}
                        checked={isTransformerChecked}
                        onChange={handleTransformerChange}
                      />{' '}
                      View Transformer
                    </p>
                  </li>

                  <li
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <p style={{ margin: 0 }}>
                      <input
                        type="color"
                        id="colorPicker"
                        name="colorPicker"
                        value={color[substationId] || '#000000'} // Ensure the color is set based on the substation
                        onChange={handleColorChange}
                        className="rounded-circle"
                        style={{
                          backgroundColor: color[substationId] || '#000000',
                          width: '20px',
                          height: '20px',
                          transform: 'translateY(2px)',
                        }}
                      />
                      &nbsp; Line Color
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(LineModal);

