// Sidebar.js
import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./css/Sidebar.css";
import axios from "axios";
import { createProxyMiddleware } from "http-proxy-middleware";

const RightSidebar = ({
  selectedFeature,
  setOpenedMenuOption,
  sendTracingData,
  networkDetails,
  getidInRighSidebar,
}) => {
  // console.log(selectedFeature, "selectedFeature");
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [opendOption, setOpendOption] = useState(setOpenedMenuOption);
  const [tracingDataNew, sendTracingDataNew] = useState(false);
  const [dataOnHandleEditClick, setDataOnHandleEditClick] = useState(false);
  const [savedFormData, setSavedFormData] = useState(false);
  const [selectedLOcation, setSelectedLOcation] = useState(null); // State to hold consumer details
  const [formData, setFormData] = useState({});
  const [cosumers, setCosumers] = useState([]);
  const [selectedFeatureCode, GetSelectedFeatureCode] = useState(selectedFeature);


  useEffect(() => {
    // Set form data based on selectedFeature
    if (selectedFeature) {
      setFormData(selectedFeature);
    }
  }, [selectedFeature]);

  useEffect(() => {
    var tabName = setOpenedMenuOption ? setOpenedMenuOption.tab : null;
    setOpendOption(tabName);
    if (tabName === "properties" || tabName === "tracing" || tabName === "sld")
      setIsClassAdded(true);
    async function fetchData() {
      let authDetails = JSON.parse(localStorage.getItem("_auth"));
      if (typeof authDetails?._token !== "undefined") {
        const formdata = new FormData();
        formdata.append("project_id", getidInRighSidebar.project_id);
        formdata.append("location_id", selectedFeature.element_id);

        const requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };
        fetch(
          process.env.REACT_APP_API_URL + `/api/get-cuonsumer-details`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result, "result");
            setCosumers(result?.data || []);
          })
          .catch((error) => {
            console.error("Error fetching consumer details:", error);
          });
      }
    }
    // get consumer details
    if (tabName === "sld") {
      setCosumers({});
      setSelectedLOcation(selectedFeature);
      fetchData();
    }
  }, [setOpenedMenuOption]);

  async function handleEditClickSave() {
    setSavedFormData(formData);
    let authDetails = JSON.parse(localStorage.getItem("_auth"));
    if (authDetails && authDetails._token) {
      const combinedData = {
        ...formData,
        project_id: getidInRighSidebar.project_id,
        element_id: selectedFeature.element_id,
      };
  
      const formdatas = new FormData();
      formdatas.append("point_props", JSON.stringify(combinedData));
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${authDetails._token}`,
        },
        body: formdatas,
        redirect: "follow",
      };
  
      fetch(
        process.env.REACT_APP_API_URL + `/api/update-point-properties/${selectedFeature.element_id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("Successfully updated point properties:", result);
          setCosumers(result?.data || []);
        })
        .catch((error) => {
          console.error("Error fetching consumer details:", error);
        });
    }
  }

  const handleTracing = (type) => {
    sendTracingData(type);
    setIsClassAdded(true);
    if (type === "start" || type === "end") setIsClassAdded(false);
  };

  const rightSubmenuRef = useRef(null);
  const removeClass = () => {
    if (rightSubmenuRef.current) {
      setIsClassAdded(false);
      //rightSubmenuRef.current.classList.remove('visible');
    }
  };
  const handleCallApi = () => {
    sendTracingDataNew(true);
  };
  const openRightSubmenu = (tabName) => {
    const anchor = document.querySelector(".leaflet-popup-close-button");
    if (anchor) {
      anchor.click();
    }
    if (opendOption === tabName && isClassAdded === true) {
      setIsClassAdded(false);
    } else {
      setIsClassAdded((prevState) => !prevState);
    }
    setOpendOption(tabName);
  };
  const handleEditClick = () => {
    setDataOnHandleEditClick(true);
  };

  const handleEditClickCancle = () => {
    setDataOnHandleEditClick(false);
  };

  let solarMeterSum = 0;
  let solarLoadSum = 0;
  let LoadSum = 0;
  let Consumers = 0;
  if (Array.isArray(cosumers)) {
    cosumers.forEach((item) => {
      solarMeterSum += Number(item?.solar_meters) || 0;
      solarLoadSum += Number(item?.soalr_load) || 0;
      LoadSum += Number(item?.load) || 0;
      Consumers += Number(item?.consumers) || 0;
    });
  }

  const renderSummary = () => (
    // <div style={{ display: "flex",justifyContent:'space-between',width:'100%' }}>
    //   <div>
    //     <div>
    //       <span style={{fontSize:"14px"}}><span style={{fontSize:"14px",fontWeight:700}}>Solar</span></span>
    //     </div>
    //     <div>
    //       <span style={{fontSize:"14px"}}><span style={{fontSize:"14px"}}>{solarMeterSum} Nos</span></span>
    //     </div>
    //     <div>
    //       <span style={{fontSize:"14px"}}><span style={{fontSize:"14px"}}>{solarLoadSum} KW</span></span>
    //     </div>
    //   </div>
    //   <div>
    //     <div>
    //       <span style={{fontSize:"14px",fontWeight:700}}>Consumers</span>
    //     </div>
    //     <div>
    //       {Array.isArray(cosumers) && <span style={{fontSize:"14px"}}>{Consumers} Nos</span>}
    //     </div>
    //     <div>
    //       {Array.isArray(cosumers) && <span style={{fontSize:"14px"}}>{LoadSum} KW</span>}
    //     </div>
    //   </div>
    // </div>
  // New Stucture
    <tr>
      <td>
          <b>Solar</b>
          <span className="d-block">{solarMeterSum} Nos</span>
          <span className="d-block">{solarLoadSum} KW</span>
      </td>
      <td className="text-center">
          <b>Consumers</b>
          {Array.isArray(cosumers) && <span className="d-block">{Consumers} Nos</span>}
          {Array.isArray(cosumers) && <span className="d-block">{LoadSum} KW</span>}
      </td>
  </tr>

  

  );
  return (
    <div className="rightsidebar">
      {isClassAdded ? (
        <div
          ref={rightSubmenuRef}
          className={`sub-sidebar-wrap sub-menu ${
            isClassAdded ? "visible" : ""
          }`}
        >
          {selectedFeature ? (
            <>
              <ul
                data-parent="properties-bar"
                className={`${
                  opendOption === "properties" ? "d-block" : "d-none"
                }`}
              >
                <li>
                  <h6 className="sub-menu-title mb-0">Properties</h6>
                </li>
                {dataOnHandleEditClick === true ? (
                  <>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.support_type"
                            >
                              Support Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.support_type"
                              value={formData.support_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  support_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select support type</option>
                              <option value="PSC">PSC</option>
                              <option value="MS GIRDER">MS GIRDER</option>
                              <option value="TABULAR">TABULAR</option>
                              <option value="FRP">FRP</option>
                              <option value="RAIL">RAIL</option>
                              <option value="PLINTH">PLINTH</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.structure_type"
                            >
                              Structure Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.structure_type"
                              value={formData.structure_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  structure_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select structure type</option>
                              <option value="Double Pole">Double Pole</option>
                              <option value="Single Pole">Single Pole</option>
                              <option value="Double Pole+EPD">
                                Double Pole+EPD
                              </option>
                              <option value="Plinth">Plinth</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.la_type"
                            >
                              LA Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.la_type"
                              value={formData.la_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  la_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select LT type</option>
                              <option value="Fix">Fix</option>
                              <option value="Fuse">Fuse</option>
                              <option value="Not Installed">
                                Not Installed
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.ht_fuse_type"
                            >
                              HT Fuse Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.ht_fuse_type"
                              value={formData.ht_fuse_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  ht_fuse_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select HT fuse type</option>
                              <option value="Drop Out Fuse">
                                Drop Out Fuse
                              </option>
                              <option value="Rewirable Fuse">
                                Rewirable Fuse
                              </option>
                              <option value="HRC Fuse">HRC Fuse</option>
                              <option value="Not Installed">
                                Not Installed
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.lt_fuse_type"
                            >
                              LT Fuse Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.lt_fuse_type"
                              value={formData.lt_fuse_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  lt_fuse_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select LT fuse type</option>
                              <option value="Rewirable Fuse">
                                Rewirable Fuse
                              </option>
                              <option value="HRC Fuse">HRC Fuse</option>
                              <option value="Circuit Breaker">
                                Circuit Breaker
                              </option>
                              <option value="Drop Out Fuse">
                                Drop Out Fuse
                              </option>
                              <option value="Not Installed">
                                Not Installed
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.earthing_type"
                            >
                              Earthing Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.earthing_type"
                              value={formData.earthing_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  earthing_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select earthing type</option>
                              <option value="GI Wire">GI Wire</option>
                              <option value="GI Strip">GI Strip</option>
                              <option value="Not Provided">Not Provided</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.xmr_type"
                            >
                              Transformer Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.xmr_type"
                              value={formData.xmr_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  xmr_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select transformer type</option>
                              <option value="Dist Transformer(Oil)">
                                Dist Transformer(Oil)
                              </option>
                              <option value="Dist Trans (Oil+LV Encl)">
                                Dist Trans (Oil+LV Encl)
                              </option>
                              <option value="Dist Transformer(Dry)">
                                Dist Transformer(Dry)
                              </option>
                              <option value="WDT">WDT</option>
                              <option value="SDT">SDT</option>
                              <option value="PLMT">PLMT</option>
                              <option value="Power Transformer">
                                Power Transformer
                              </option>
                              <option value="Station Transformer">
                                Station Transformer
                              </option>
                              <option value="Buster Transformer">
                                Buster Transformer
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.xmr_core_type"
                            >
                              Core Type *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.xmr_core_type"
                              value={formData.xmr_core_type || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  xmr_core_type: e.target.value,
                                })
                              }
                            >
                              <option value="">Select core type</option>
                              <option value="CRGO">CRGO</option>
                              <option value="Amorphous">Amorphous</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.capacity"
                            >
                              Capacity *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.capacity"
                              value={formData.capacity || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  capacity: e.target.value,
                                })
                              }
                            >
                              <option value="">Select capacity</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="16">16</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="63">63</option>
                              <option value="75">75</option>
                              <option value="100">100</option>
                              <option value="150">150</option>
                              <option value="200">200</option>
                              <option value="250">250</option>
                              <option value="315">315</option>
                              <option value="350">350</option>
                              <option value="500">500</option>
                              <option value="750">750</option>
                              <option value="800">800</option>
                              <option value="900">900</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.has_fencing"
                            >
                              Fencing Available *
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.has_fencing"
                              value={formData.has_fencing || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  has_fencing: e.target.value,
                                })
                              }
                            >
                              <option value="">Select option</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.scheme"
                            >
                              Scheme
                            </label>
                            <select
                              className="form-select form-select-sm"
                              id="htobj.scheme"
                              value={formData.scheme || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  scheme: e.target.value,
                                })
                              }
                            >
                              <option value="">Select scheme</option>
                              <option value="ND">ND</option>
                              <option value="DISS">DISS</option>
                              <option value="DDUJGY">DDUJGY</option>
                              <option value="IPDS">IPDS</option>
                              <option value="KHUSHY">KHUSHY</option>
                              <option value="SSVY">SSVY</option>
                              <option value="ZP-SCSP">ZP-SCSP</option>
                              <option value="RDSS">RDSS</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.comm_date"
                            >
                              Commissioning Date
                            </label>
                            <input
                              placeholder="Enter commissioning date"
                              type="date"
                              id="htobj.comm_date"
                              className="form-control form-control-sm"
                              value={formData.commissioning_date || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  commissioning_date: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb-3">
                            <label
                              className="form-label1"
                              htmlFor="htobj.loc_name"
                            >
                              Location Name
                            </label>
                            <input
                              placeholder="Enter location name"
                              type="text"
                              id="htobj.loc_name"
                              className="form-control form-control-sm"
                              value={formData.loc_name || ""}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  loc_name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  Object.keys(selectedFeature).map((innerAttr, index) => {
                    console.log(innerAttr, "innerAttr");
                    if (selectedFeature[innerAttr] === null)
                      selectedFeature[innerAttr] = "N/A"; // Handling null values
                    return (
                      <>
                        <ul className="sub-menu-child" key={index}>
                          <li className="label-name">
                            {innerAttr.replace(/_/g, " ")}
                          </li>
                          <li>{selectedFeature[innerAttr]}</li>
                        </ul>
                      </>
                    );
                  })
                )}
                {dataOnHandleEditClick === false ? (
                  <>
                    <button
                      style={{
                        padding: "8px 30px",
                        backgroundColor: "blue",
                        borderRadius: "8px",
                        color: "white",
                        fontSize: "12px",
                        marginTop: "20px",
                      }}
                      onClick={handleEditClick}
                    >
                      Edit
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      style={{
                        padding: "8px 30px",
                        backgroundColor: "blue",
                        borderRadius: "8px",
                        color: "white",
                        fontSize: "12px",
                        marginTop: "20px",
                      }}
                      onClick={handleEditClickSave}
                    >
                      Save
                    </button>
                    <button
                      style={{
                        padding: "8px 30px",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        color: "black",
                        fontSize: "12px",
                        marginTop: "20px",
                        marginLeft: "10px",
                        border: "1px solid black",
                      }}
                      onClick={handleEditClickCancle}
                    >
                      Cancle
                    </button>
                  </>
                )}
              </ul>
              <ul
                data-parent="sld-bar"
                className={`${opendOption === "sld" ? "d-block" : "d-none"}`}
              >
                <li>
                  <h6 className="sub-menu-title mb-0">SLD view</h6>
                </li>
                <li>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    className="diagram-tbl-chart mx-auto mt-3"
                  >
                    <tbody>
                      <tr>
                        <td colSpan={2}>
                          <hr className="m-0" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ position: "relative" }}>
                            <img
                              src="sld/new_image/LA-New.svg"
                              style={{ "padding-right": "0", "width": "100%" }}
                              className="ms-auto d-block"
                              alt="LA"
                              title="LA 230V"
                            />                            
                          </div>
                        </td>
                        <td valign="middle">
                          <label>
                              LA
                            </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ position: "relative" }}>
                            <img
                              src="sld/teletransmitted_switches/fuse_active.svg"
                              className="mx-auto d-block"
                              alt="fuse"
                            />                            
                          </div>
                        </td>
                        <td valign="middle">
                          <label>
                              HT Fuse
                            </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ position: "relative" }}>
                            <img
                              src="sld/new_image/transformer.svg"
                              className="mx-auto d-block"
                              alt="Transformer"
                            />
                          </div>
                        </td>
                        <td>
                          <label>
                          <span>Transformer</span><br/>
                            {selectedFeature?.xmr_capacity ?? 0} KVA
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style={{ position: "relative" }}>
                            <img
                              src="sld/teletransmitted_switches/Fuse.svg"
                              className="mx-auto d-block"
                              alt="fuse"
                            />
                          </div>
                        </td>
                        <td>
                          <label>
                              LT fuse
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <hr className="m-0" />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <table style={{
                                "width": "100%",
                              }}>
                            <tr>
                              <td valign="top" style={{"width" : "50%"}}>
                                <img
                                  src="sld/new_image/solar-icon.svg"
                                  className="d-block"
                                  alt="Solar load"
                                />
                              </td>
                              <td valign="top" style={{"width" : "50%"}}>
                                <img
                                  src="sld/new_image/Consumers-icon.svg"
                                  className="mx-auto d-block"
                                  alt="load"
                                />
                              </td>
                            </tr>
                            {renderSummary()}
                          </table>
                        </td>                              
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <a href="#">
                    <img
                      src="sld/teletransmitted_switches/isolator_group_b_closed2.svg"
                      className="mx-auto d-block"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </>
          ) : (
            ""
          )}
          <ul
            data-parent="tracing-bar"
            className={`${opendOption === "tracing" ? "d-block" : "d-none"}`}
          >
            <li>
              <h6 className="sub-menu-title mb-0">Tracing</h6>
            </li>
            <li>
              <a href="#" onClick={() => handleTracing("down")}>
                Downward
              </a>
            </li>
            {selectedFeature && selectedFeature["point_name"] !== "Gentry" ? (
              <li>
                <a href="#" onClick={() => handleTracing("up")}>
                  Upward
                </a>
              </li>
            ) : (
              ""
            )}
            <li>
              <h6 className="sub-menu-title mb-0">Trace Between</h6>
            </li>
            <li>
              <a href="#" onClick={() => handleTracing("start")}>
                Start Point
              </a>
            </li>
            <li>
              <a href="#" onClick={() => handleTracing("end")}>
                End Point
              </a>
            </li>

            {networkDetails.pathLength && networkDetails.pathLength > 0 ? (
              <>
                <li>
                  <h6 className="sub-menu-title mb-0">Network Details</h6>
                </li>
                <li>
                  <a href="#">
                    Total length: {networkDetails.pathLength ?? 0} km
                  </a>
                </li>
                <li>
                  <a href="#">Total span: {networkDetails.spanCount ?? 0} </a>
                </li>
                <li>
                  <a href="#">
                    Total Transformer: {networkDetails.Transformer ?? 0}{" "}
                  </a>
                </li>
                <li>
                  <a href="#">Total Switch: {networkDetails.Switch ?? 0} </a>
                </li>
                <li>
                  <a href="#">
                    Total XMR Capacity: {networkDetails.Capacity ?? 0} KVA{" "}
                  </a>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
          <ul
            data-parent="map-tool-bar"
            className={`${opendOption === "map_tool" ? "d-block" : "d-none"}`}
          >
            <li>
              <h6 className="sub-menu-title mb-0">Map Tool</h6>
            </li>
            <li>
              <a href="#" onClick={() => handleTracing("length")}>
                Length
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#cccecf" }}>
                Area
              </a>
            </li>
          </ul>
          <ul
            data-parent="draw-bar"
            className={`${opendOption === "draw" ? "d-block" : "d-none"}`}
            onClick={handleCallApi}
          >
            <li>
              <h6 className="sub-menu-title mb-0">Draw</h6>
            </li>
            <li>
              
              <a href="#" style={{ color: "#cccecf" }}>
                Point{" "}
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#cccecf" }}>
                Line{" "}
              </a>
            </li>
            <li>
              <a href="#" style={{ color: "#cccecf" }}>
                Polygon{" "}
              </a>
            </li>
          </ul>

          
          <ul data-parent="style-bar" className="d-none">
            <li>
              <h6 className="sub-menu-title mb-0">Style</h6>
            </li>
            <li>
              <a href="#"></a>
            </li>
          </ul>
          <button className="close-submenu" onClick={removeClass}>
            <img src="right-double-chevron.png" alt="" />
          </button>
        </div>
      ) : (
        ""
      )}
      <div className="main-menu">
        <ul className="rightsidebar-menu">
          {selectedFeature ? (
            <>
              <li>
                <a
                  onClick={() => openRightSubmenu("properties")}
                  title="Properties"
                >
                  <img
                    src="Properties.svg"
                    className="sidebar-icon"
                    alt="properties"
                  />
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    openRightSubmenu("sld");
                    // Add your additional onClick functionality here
                  }}
                  title="sld view"
                >
                  <img
                    src="sld/transformer_white.svg"
                    className="sidebar-icon"
                    alt="SLD"
                  />
                </a>
              </li>
              <li>
                <a onClick={() => openRightSubmenu("tracing")} title="Tracing">
                  <img
                    src="Tracing.svg"
                    className="sidebar-icon"
                    alt="tracing"
                  />
                </a>
              </li>
            </>
          ) : (
            ""
          )}
          {/* <li>
            <a onClick={() => openRightSubmenu("map_tool")} title="Map Tool">
              <img src="Map-Tool.svg" className="sidebar-icon" alt="map_tool" />
            </a>
          </li>
          <li>
            <a onClick={() => openRightSubmenu("draw")} title="Draw">
              <img src="Draw.svg" className="sidebar-icon" alt="draw" />
              <span>Draw</span>
            </a>
          </li>
          <li>
            <a href="#">
              <img src="Style.svg" className="sidebar-icon" alt="" />
              <span>Style</span>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default RightSidebar;
