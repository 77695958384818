import React, { useState } from 'react';
import './css/login.css';

const LoginPage = ({onLogin}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    // Here you can implement the login authentication logic
    // For demonstration purposes, let's just show an error if username or password is empty
    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }
    try {
        // const response = await fetch(process.env.REACT_APP_API_URL + '/api/login', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     "Access-Control-Allow-Origin": 'fornaxenergytech.com, geo-tag.nividasoftware.com, google.com',
        //     "Access-Control-Allow-Methods": "PUT, PATCH, DELETE, POST, GET, OPTIONS",
        //     "Access-Control-Allow-Headers": "Accept, X-Requested-With, Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token, Authorization, true"
        //   },
        //   body: JSON.stringify({ email:username, password:password }),
        // });

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        const formdata = new FormData();
        formdata.append("email", username);
        formdata.append("password", password);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow"
        };

        fetch(process.env.REACT_APP_API_URL + '/api/login', requestOptions)
        .then((response) => {
            // Check if response is successful (status 200)
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            // Parse response as JSON
            return response.json();
        })
        .then((result) => {
            // Log the result
            if (result.success !== true) {
              throw new Error(result.message || 'Login failed');
            }
            localStorage.setItem('user', JSON.stringify(result.data.user));
            onLogin(result.data);
            // You can further process the result here
        })
        .catch((error) => console.error(error));

        // If login is successful, you can redirect the user to another page
        // For now, let's just show a success message
        //alert('Login successful');
      } catch (error) {
        setError(error.message);
      }
  };

  return (
    <div className="login-container">
      <h2><b>Fornax Mapview</b></h2><br/>
      <h4>Login here</h4>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <div className="error-message">{error}</div>}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default LoginPage;
