import React, { useEffect, useState, memo } from 'react';
// import { MdOutlineBorderColor } from "react-icons/md";
// import { IoMdColorFill } from "react-icons/io";
import '../StyleModal/StyleModal'; // Import your CSS file
// import { zIndex } from 'html2canvas/dist/types/css/property-descriptors/z-index';
// import { transformScale } from '@turf/turf';

const InfoModel = ({ infoResult, handleClose,show}) => {


  return (
    <>
    


        <div className="modal-overlay" style={{ marginTop: show ? '535px' : '5px',transition: 'margin-top .3s ease-in' }}
        >
          <div className="modal-content" style={{ padding:'10px'}}>
            <div style={{background:'#6B8A7A', color:'#fff'}}>
            <button className="modal-close-button" onClick={handleClose}>
              &times;
            </button>
            <h5 className="modal-title"> Informatiion</h5>
            </div>

{infoResult ? (
<div class="container m-1" >
    <div class="card"  style={{border:0}}>
    <div class="card-body">
    <p><strong> Code:</strong> {infoResult.organization_type ?  infoResult.organization_type.charAt(0).toUpperCase() + infoResult.organization_type.slice(1).toLowerCase() : 'No Data Found'}</p>

            <p><strong> Name:</strong> {infoResult.organization_name ? infoResult.organization_name : 'No Data Found'}</p>
            <p><strong>Area :</strong> {infoResult.area ? infoResult.area + ' km²': 'No Data Found'}</p>
        </div>
    </div>
</div>
        ): null}    
        </div></div>


    </>
  );
};

export default memo(InfoModel);
